import { Navigate, useRoutes } from "react-router-dom";
import ReactPDF from "@react-pdf/renderer";
// layouts
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
//
import Login from "./pages/Login";
import Register from "./pages/Register";
import DashboardApp from "./pages/DashboardApp";
import Products from "./pages/Products";
import Blog from "./pages/Blog";
import User from "./pages/User";
import Productions from "./pages/productions/Productions";
import CreateProduction from "./pages/productions/Create";
import UpdateProduction from "./pages/productions/Update";
import NotFound from "./pages/Page404";
import Unauthorized from "./pages/Page400";
import Employees from "./pages/employees/Employees";
import CreateEmployee from "./pages/employees/Create";
import Vendors from "./pages/vendors/Vendors";
import CreateVendor from "./pages/vendors/Create";
import UpdateVendor from "./pages/vendors/Update";
import Vehicles from "./pages/vehicles/Vehicles";
import CreateVehicle from "./pages/vehicles/Create";
import DeliveryNotes from "./pages/deliverynotes/DeliveryNotes";
import CreateDeliveryNote from "./pages/deliverynotes/Create";
import StoreOuts from "./pages/storeouts/StoreOuts";
import CreateStoreOut from "./pages/storeouts/Create";
import Payments from "./pages/payments/Payments";
import CreatePayment from "./pages/payments/Create";

import MyDocument from "./utils/MyDocument";
import StoreBacks from "./pages/storebacks/StoreBacks";
import CreateStoreBack from "./pages/storebacks/Create";
import Dispenses from "./pages/dispenses/Dispenses";
import CreateDispense from "./pages/dispenses/Create";
import CreateDispenseLine from "./pages/dispenses/CreateLine";

import Users from "./pages/users/Users";
import Actions from "./pages/actions/Actions";
import UpdateRoles from "./pages/users/UpdateRoles";

import AuthService from "./services/common/auth.service";
import Bookings from "./pages/bookings/Bookings";
import CreateBooking from "./pages/bookings/Create";
import CreateBookingH from "./pages/bookings/CreateH";
import Booking from "./pages/bookings/Booking";
import BookingHotel from "./pages/bookings/BookingHotel";
import BookingsHotel from "./pages/bookings/BookingsHotel";
import React from "react";
import { Auth } from "aws-amplify";
import ZooPriceSetting from "./pages/bookings/settings/ZooPriceSetting";

export default function Router({ user }) {
  return useRoutes([
    {
      path: "/dashboard",
      //element: <DashboardLayout />,
      element:
        user !== null && user !== undefined ? (
          <DashboardLayout user={user} />
        ) : (
          <Navigate to="/login" />
        ),
      children: [
        { path: "/", element: <Navigate to="/dashboard/app" replace /> },
        { path: "app", element: <DashboardApp user={user} /> },
        {
          path: "bookings",
          element: <Bookings />,
        },
        {
          path: "bookingshotel",
          element: <BookingsHotel />,
        },
        {
          path: "bookings/update",
          element: <CreateBooking />,
        },
        {
          path: "bookingshotel/update",
          element: <CreateBookingH />,
        },

        { path: "settingzprices", element: <ZooPriceSetting /> },
        { path: "employees/create", element: <CreateEmployee /> },
        { path: "products", element: <Products /> },
        // {
        //   path: "blog",
        //   element: ReactPDF.renderToStream(<MyDocument />),
        // },
      ],
    },
    {
      path: "/",
      element: <LogoOnlyLayout />,
      children: [
        { path: "booking", element: <Booking /> },
        { path: "bookinghotel", element: <BookingHotel /> },
        {
          path: "login",
          element:
            user !== null && user !== undefined ? (
              <Navigate to="/dashboard" />
            ) : (
              <Login />
            ),
        },
        { path: "register", element: <Register /> },
        { path: "404", element: <NotFound /> },
        { path: "400", element: <Unauthorized /> },
        { path: "/", element: <Navigate to="/dashboard" /> },
      ],
    },
  ]);
}
