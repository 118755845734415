import http from './common/http-common';

const getAll = (params) => http.get('/employees/sorted', { params });

const save = (params) =>
  http.post('/employees/', params);

// other CRUD methods

export default {
  getAll,
  save
};
