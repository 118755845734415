import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Box, Card, Link, Typography, Stack } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
// utils
import { fCurrency } from '../../../utils/formatNumber';
//
import Label from '../../Label';
import ColorPreview from '../../ColorPreview';
import { mockImgProduct } from '../../../utils/mockImages';

const manAvatar = `/static/mock-images/alt-avatars/img_avatar1.png`;
const ladyAvatar = `/static/mock-images/alt-avatars/img_avatar2.png`;

// ----------------------------------------------------------------------

const ProductImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});

// ----------------------------------------------------------------------

EmployeeCard.propTypes = {
  employee: PropTypes.object
};

export default function EmployeeCard({ employee }) {
  // const { name, cover, price, colors, status, priceSale } = employee;
  const {
    id,
    lastName,
    firstName,
    hiringDate,
    gender,
    price,
    colors,
    status,
    priceSale,
    imageUrl
  } = employee;

  let imgSrc = '';
  if (imageUrl === null || imageUrl === '') {
    if (gender === 'M') {
      imgSrc = manAvatar;
    } else {
      imgSrc = ladyAvatar;
    }
  } else {
    imgSrc = imageUrl;
  }

  console.log('ID >>>>>> : ', imageUrl);
  const cover = mockImgProduct(id);

  return (
    <Card>
      <Box sx={{ pt: '100%', position: 'relative' }}>
        <Label
          variant="filled"
          color={(gender === 'M' && 'error') || 'info'}
          sx={{
            zIndex: 9,
            top: 16,
            right: 16,
            position: 'absolute',
            textTransform: 'uppercase'
          }}
        >
          {gender}
        </Label>
        <ProductImgStyle alt={firstName} src={imgSrc} />
      </Box>

      <Stack spacing={2} sx={{ p: 3 }}>
        <Link to="#" color="inherit" underline="hover" component={RouterLink}>
          <Typography variant="subtitle2" noWrap>
            Nom : {lastName}
          </Typography>
          <Typography variant="subtitle2" noWrap>
            Prénom : {firstName}
          </Typography>
        </Link>

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="subtitle2">Embauche : {hiringDate}</Typography>
        </Stack>
      </Stack>
    </Card>
  );
}
