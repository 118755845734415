import { useState } from "react";
import { useNavigate } from "react-router-dom";
// material
import { styled } from "@material-ui/core/styles";
import { Box, Card, Link, Container, Typography } from "@material-ui/core";
// layouts
import AuthLayout from "../../layouts/AuthLayout";
// components
import Page from "../../components/Page";
import CreateForm from "../../components/_dashboard/vendors/create/CreateVendorForm";
import VendorService from "../../services/vendor.service";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

// ----------------------------------------------------------------------

export default function Vendor() {
  const [serverErrors, setServerErrors] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSave = (
    lastname,
    firstname,
    idcardnumber,
    birthdate,
    hireringdate,
    phoneNumber,
  ) => {
    setIsLoading(true);
    setServerErrors("");
    VendorService.save(
      firstname,
      lastname,
      idcardnumber,
      birthdate,
      hireringdate,
      phoneNumber,
    )
      .then(
        () => {
          navigate("/dashboard/vendors");
          //window.location.reload();
        },
        (error) => {
          let resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setServerErrors(resMessage);
          setIsLoading(false);
        }
      )
      .catch(() => setIsLoading(false));
  };

  return (
    <RootStyle title="Enregistrer Vendeur">
      <Container>
        <CreateForm
          handleSave={handleSave}
          serverErrors={serverErrors}
          isLoading={isLoading}
        />
      </Container>
    </RootStyle>
  );
}
