import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// material
import { styled } from "@material-ui/core/styles";
import { Box, Card, Stack, Container, Typography } from "@material-ui/core";
// layouts
import AuthLayout from "../../layouts/AuthLayout";
// components
import Page from "../../components/Page";
import CreateForm from "../../components/_dashboard/payments/create/CreatePaymentForm";
import PaymentService from "../../services/payment.service";
import VendorService from "../../services/vendor.service";
import StoreOutService from "../../services/storeout.service";
import EventBus from "../../services/common/EventBus";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

//{idvendor}/{idvehicle}/{idnote}
//dischargeNumber,outDate,qty,supQty,totalAmount
// ----------------------------------------------------------------------

export default function Register() {
  const [serverErrors, setServerErrors] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [stores, setStores] = useState([]);
  const [idv, setIdv] = useState(0);
  const [isLoadingChild, setIsLoadingChild] = useState(false);
  const navigate = useNavigate();

  const handleSave = ({ ids, paymentDate, amountPaid }) => {
    setIsLoading(true);
    setServerErrors("");

    PaymentService.save(ids, paymentDate, amountPaid)
      .then(
        () => {
          navigate("/dashboard/accounting/payments");
          //window.location.reload();
        },
        (error) => {
          let resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setServerErrors(resMessage);
          setIsLoading(false);
        }
      )
      .catch(() => setIsLoading(false));
  };

  const retrieveDrivers = () => {
    const params = {
      size: 100,
      sort: "lastName,asc",
    };
    setIsLoadingChild(true);
    VendorService.getList(params)
      .then(
        (response) => {
          const { data } = response.data;

          setDrivers(data);
          setIsLoadingChild(false);
          console.log(response.data);
        },
        (error) => {
          if (error.response && error.response.status === 403) {
            EventBus.dispatch("logout");
          }
        }
      )
      .catch((e) => {
        setIsLoadingChild(false);
        console.log(e);
      });
  };

  const retrieveStores = () => {
    const params = {
      id: idv,
      unpaid: true,
      size: 100,
    };
    setStores([]);
    setIsLoadingChild(true);
    StoreOutService.getList(params)
      .then(
        (response) => {
          const { data } = response.data;

          setStores(data);
          setIsLoadingChild(false);
          console.log(response.data);
        },
        (error) => {
          if (error.response && error.response.status === 403) {
            EventBus.dispatch("logout");
          }
        }
      )
      .catch((e) => {
        setIsLoadingChild(false);
        console.log(e);
      });
  };

  useEffect(retrieveDrivers, []);
  useEffect(retrieveStores, [idv]);

  return (
    <RootStyle title="Saisie des recouvrements">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Saisie des recouvrements
          </Typography>
        </Stack>
        <CreateForm
          handleSave={handleSave}
          serverErrors={serverErrors}
          isLoading={isLoading}
          drivers={drivers}
          stores={stores}
          setIdv={setIdv}
          isLoadingChild={isLoadingChild}
        />
      </Container>
    </RootStyle>
  );
}
