import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// material
import { styled } from "@material-ui/core/styles";
import { Box, Card, Stack, Container, Typography } from "@material-ui/core";
// layouts
import AuthLayout from "../../layouts/AuthLayout";
// components
import Page from "../../components/Page";
import CreateForm from "../../components/_dashboard/storeouts/create/CreateStoreOutForm";
import DeliveryNoteService from "../../services/deliverynote.service";
import VendorService from "../../services/vendor.service";
import VehicleService from "../../services/vehicle.service";
import StoreOutService from "../../services/storeout.service";
import EventBus from "../../services/common/EventBus";
import { GlobalContext } from "src/context/Provider";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

//{idvendor}/{idvehicle}/{idnote}
//dischargeNumber,outDate,qty,supQty,totalAmount
// ----------------------------------------------------------------------

export default function Register() {
  const [serverErrors, setServerErrors] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [notes, setNotes] = useState([]);
  const [idv, setIdv] = useState(0);
  const [isLoadingChild, setIsLoadingChild] = useState(false);
  const navigate = useNavigate();
  const { availableStore, setAvailableStore } = useContext(GlobalContext);

  const handleSave = ({
    idvendor,
    idvehicle,
    idnote,
    outDate,
    qty,
    supQty,
    totalAmount,
    pu
  }) => {
    setIsLoading(true);
    setServerErrors("");

    StoreOutService.save(
      idvendor,
      idvehicle,
      idnote,
      outDate,
      qty,
      supQty,
      totalAmount,
      pu
    )
      .then(
        () => {
          let stk = availableStore - qty - supQty;
          setAvailableStore(stk);
          navigate("/dashboard/stocks/livraisons");
          //window.location.reload();
        },
        (error) => {
          let resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setServerErrors(resMessage);
          setIsLoading(false);
        }
      )
      .catch(() => setIsLoading(false));
  };

  const retrieveDrivers = () => {
    const params = {
      size: 100,
      sort: "lastName,asc",
    };
    setIsLoadingChild(true);
    VendorService.getList(params)
      .then(
        (response) => {
          const { data } = response.data;

          setDrivers(data);
          setIsLoadingChild(false);
          console.log(response.data);
        },
        (error) => {
          setIsLoadingChild(false);
          if (error.response && error.response.status === 403) {
            EventBus.dispatch("logout");
          }
        }
      )
      .catch((e) => {
        setIsLoadingChild(false);
        console.log(e);
      });
  };

  const retrieveNotes = () => {
    const params = {
      id: idv,
      served: false,
      size: 100,
    };
    setIsLoadingChild(true);
    DeliveryNoteService.getList(params)
      .then(
        (response) => {
          const { data } = response.data;

          setNotes(data);
          setIsLoadingChild(false);
          console.log(response.data);
        },
        (error) => {
          setIsLoadingChild(false);
          if (error.response && error.response.status === 403) {
            EventBus.dispatch("logout");
          }
        }
      )
      .catch((e) => {
        setIsLoadingChild(false);
        console.log(e);
      });
  };

  const retrieveVehicles = () => {
    const params = {
      size: 100,
      sort: "immatriculation,asc",
    };
    VehicleService.getList(params)
      .then(
        (response) => {
          const { data } = response.data;

          setVehicles(data);

          console.log(response.data);
        },
        (error) => {
          if (error.response && error.response.status === 403) {
            EventBus.dispatch("logout");
          }
        }
      )
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(retrieveDrivers, []);
  useEffect(retrieveVehicles, []);
  useEffect(retrieveNotes, [idv]);

  return (
    <RootStyle title="Saisie de chargements">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Saisie de chargements pour livraisons
          </Typography>
        </Stack>
        <CreateForm
          handleSave={handleSave}
          serverErrors={serverErrors}
          isLoading={isLoading}
          drivers={drivers}
          vehicles={vehicles}
          notes={notes}
          setIdv={setIdv}
          isLoadingChild={isLoadingChild}
        />
      </Container>
    </RootStyle>
  );
}
