// ----------------------------------------------------------------------
const user = JSON.parse(localStorage.getItem("perfectuser"));

const account = {
  displayName: user !== null ? user.firstname + " "+user.lastname : "",
  email: user !== null ? user.email : "",
  photoURL: "/static/mock-images/avatars/avatar_default.jpg",
};

export default account;
