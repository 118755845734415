import React, { useEffect } from "react";
import Amplify, { Auth, Hub } from "aws-amplify";
// routes
import Router from "./routes";
// theme
import ThemeConfig from "./theme";
// components
import ScrollToTop from "./components/ScrollToTop";
import EventBus from "./services/common/EventBus";
import AuthService from "./services/common/auth.service";

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  },
  // aws_cognito_region: process.env.REACT_APP_REGION, // (required) - Region where Amazon Cognito project was created
  // aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID, // (optional) -  Amazon Cognito User Pool ID
  // aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID, // (optional) - Amazon Cognito App Client ID (App client secret needs to be disabled)
  // aws_mandatory_sign_in: 'enable' // (optional) - Users are not allowed to get the aws credentials unless they are signed in
});

// ----------------------------------------------------------------------

export default function App() {
  const [user, setUser] = React.useState(null);

  async function getUser() {
    return await Auth.currentAuthenticatedUser()
      .then((userData) => userData)
      .catch(() => console.log("Not signed in"));
  }

  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          getUser().then((userData) => setUser(userData));
          break;
        case "signOut":
          setUser(null);
          break;
        case "signIn_failure":
          console.log("Sign in failure", data);
          break;
        default:
          break;
      }
    });

    getUser().then((userData) => setUser(userData));
  }, []);

  return (
    <ThemeConfig>
      <ScrollToTop />
      <Router user={user} />
    </ThemeConfig>
  );
}
