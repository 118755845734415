import authHeader from "./common/auth-header";
import http from "./common/http-common";

const getList = (params) => {
  return http.get("/bookings", { params, headers: authHeader() });
};

const getItem = (params) => {
  return http.get("/bookings/item", { params, headers: authHeader() });
};

const pay = (values) =>
  http.post("/pay-booking/pay", values, {
    headers: authHeader(),
  });

const terminate = (visit_date, tx_reference, pk) =>
  http.post(
    "/bookings/zoo/terminate",
    { visit_date, tx_reference, pk },
    {
      headers: authHeader(),
    }
  );

const save = (values) =>
  http.post("/booking", values, {
    headers: authHeader(),
  });

const updateParams = (values, params) =>
  http.post("/params/setting", values, { params, headers: authHeader() });

const getParams = (params) => {
  return http.get("/params", { params, headers: authHeader() });
};

export default {
  getItem,
  getList,
  getParams,
  terminate,
  save,
  updateParams,
  pay,
};
