import React, { useState, useEffect } from "react";

import { Icon } from "@iconify/react";
import androidFilled from "@iconify/icons-ant-design/home-outline";
// material
import { alpha, styled } from "@material-ui/core/styles";
import { Card, Typography, CircularProgress, Stack } from "@material-ui/core";
// utils
import { fShortenNumber } from "../../../utils/formatNumber";

import StoreOutService from "../../../services/storeout.service";
import EventBus from "../../../services/common/EventBus";
import Label from "src/components/Label";
import AuthService from "src/services/common/auth.service";

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  textAlign: "center",
  padding: theme.spacing(5, 0),
  color: theme.palette.primary.darker,
  backgroundColor: theme.palette.primary.lighter,
}));

const IconWrapperStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: "center",
  marginBottom: theme.spacing(3),
  color: theme.palette.primary.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(
    theme.palette.primary.dark,
    0
  )} 0%, ${alpha(theme.palette.primary.dark, 0.24)} 100%)`,
}));

// ----------------------------------------------------------------------

const TOTAL = 714000;

export default function AppMonthlyHotelBookings({
  start,
  end,
  data,
  isBusy,
  setHotelTotalAmount,
}) {
  const [total, setTotal] = useState(0);
  const [nbVisites, setNbVisites] = useState(0);
  const [mtVisites, setMtVisites] = useState(0);
  const [nbRes, setNbRes] = useState(0);
  const [mtRes, setMtRes] = useState(0);

  const retrieveData = () => {
    let nbV = 0;
    let mtV = 0;
    let nbR = 0;
    let mtR = 0;
    if (data !== undefined) {
      data.forEach((p) => {
        //console.log(JSON.stringify(p));
        // Res en cours, pas encore visité
        nbR++;
        mtR = mtR + p.amount;
        if (p.status === "DONE") {
          // console.log("DE");
          // Visite effectuee
          nbV++;
          mtV = mtV + p.amount;
        }
      });
    }
    let tot = nbR === 0 ? 0 : (nbV * 100) / nbR;
    setTotal(tot);
    setNbRes(nbR);
    setNbVisites(nbV);
    setMtRes(mtR);
    setMtVisites(mtV);
    setHotelTotalAmount(mtR);
  };

  useEffect(retrieveData, [start, end, data]);

  return (
    <RootStyle>
      <Typography variant="h4" sx={{ opacity: 0.72, paddingBottom: 5 }}>
        Statistiques de l'Hôtel
      </Typography>
      <IconWrapperStyle>
        <Icon icon={androidFilled} width={24} height={24} />
      </IconWrapperStyle>
      {isBusy ? (
        <CircularProgress />
      ) : (
        <>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              paddingLeft: 2,
              paddingRight: 2,
              justifyContent: "space-between",
            }}
          >
            <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
              Nb de réservations :{" "}
            </Typography>
            <Typography variant="subtitle2">{fShortenNumber(nbRes)}</Typography>
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              paddingLeft: 2,
              paddingRight: 2,
              justifyContent: "space-between",
            }}
          >
            <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
              Montant des réservations (FCFA):{" "}
            </Typography>
            <Typography variant="subtitle2">{fShortenNumber(mtRes)}</Typography>
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              paddingLeft: 2,
              paddingRight: 2,
              justifyContent: "space-between",
            }}
          >
            <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
              Nb de passages :{" "}
            </Typography>
            <Typography variant="subtitle2">
              {fShortenNumber(nbVisites)}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              paddingLeft: 2,
              paddingRight: 2,
              justifyContent: "space-between",
            }}
          >
            <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
              Montant des passages (FCFA):{" "}
            </Typography>
            <Typography variant="subtitle2">
              {fShortenNumber(mtVisites)}
            </Typography>
          </Stack>

          <Stack
            direction="row"
            spacing={2}
            sx={{
              paddingLeft: 2,
              paddingRight: 2,
              justifyContent: "space-between",
            }}
          >
            <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
              Ratio des Passages/Réservations (%):{" "}
            </Typography>
            <Typography variant="subtitle2">{fShortenNumber(total)}</Typography>
          </Stack>
        </>
      )}
    </RootStyle>
  );
}
