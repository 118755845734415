import { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// material
import { styled } from "@material-ui/core/styles";
import { Box, Card, Link, Container, Typography } from "@material-ui/core";
// layouts
import AuthLayout from "../layouts/AuthLayout";
// components
import Page from "../components/Page";
import { MHidden } from "../components/@material-extend";
import { RegisterForm } from "../components/authentication/register";
import AuthSocial from "../components/authentication/AuthSocial";
import AuthService from "../services/common/auth.service";
import { APP_DESC } from "../utils/appConstants";
import sideimage from "../img/girl-one.png"; // Tell webpack this JS file uses this image
import { Auth } from 'aws-amplify';


// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Register() {
  const [serverErrors, setServerErrors] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleRegister = async (email, password, firstName, lastName) => {
    setIsLoading(true);
    setServerErrors("");
    try {
      await Auth.signUp({
        username: email,
        password: password,
        attributes: {
          email: email
        }});
      /* Once the user successfully signs up, update form state to show the confirm sign up form for MFA */
      //formState = "confirmSignUp";
    } catch (err) { console.log({ err }); }
    
    AuthService.register(email, password, firstName, lastName)
      .then(
        () => {
          navigate("/login");
        },
        (error) => {
          let resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setServerErrors(resMessage);
          setIsLoading(false);
        }
      )
      .catch(() => setIsLoading(false));
  };

  return (
    <RootStyle title="Inscription | Perfect Management App">
      <AuthLayout>
        Déjà inscrit? &nbsp;
        <Link
          underline="none"
          variant="subtitle2"
          component={RouterLink}
          to="/login"
        >
          Connexion
        </Link>
      </AuthLayout>

      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            {APP_DESC}
          </Typography>
          <img alt="register" src={sideimage} />
        </SectionStyle>
      </MHidden>

      <Container>
        <ContentStyle>
          <Box sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Inscription pour utilisation de l'Application
            </Typography>
            <Typography sx={{ color: "text.secondary" }}>
              Renseigner votre identité ici.
            </Typography>
          </Box>

          <RegisterForm
            handleRegister={handleRegister}
            serverErrors={serverErrors}
            isLoading={isLoading}
          />

          <MHidden width="smUp">
            <Typography variant="subtitle2" sx={{ mt: 3, textAlign: "center" }}>
            Déjà inscrit? &nbsp;
              <Link to="/login" component={RouterLink}>
                Connexion
              </Link>
            </Typography>
          </MHidden>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
