import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import windowsFilled from "@iconify/icons-ant-design/money-collect-outline";
// material
import { alpha, styled } from "@material-ui/core/styles";
import { Card, Typography, CircularProgress, Stack } from "@material-ui/core";
// utils
import { fShortenNumber } from "../../../utils/formatNumber";
import StoreOutService from "../../../services/storeout.service";
import EventBus from "../../../services/common/EventBus";
import AuthService from "src/services/common/auth.service";
import Label from "src/components/Label";

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  textAlign: "center",
  padding: theme.spacing(5, 0),
  color: theme.palette.warning.darker,
  backgroundColor: theme.palette.warning.lighter,
}));

const IconWrapperStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: "center",
  marginBottom: theme.spacing(3),
  color: theme.palette.warning.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(
    theme.palette.warning.dark,
    0
  )} 0%, ${alpha(theme.palette.warning.dark, 0.24)} 100%)`,
}));

// ----------------------------------------------------------------------

const TOTAL = 1723315;

export default function AppMonthlyZooHotelBookings({
  start,
  end,
  data,
  isBusy,
  zooTotalAmount,
  hotelTotalAmount,
}) {
  const [total, setTotal] = useState(0);
  const [nbVisites, setNbVisites] = useState(0);
  const [mtVisites, setMtVisites] = useState(0);
  const [nbRes, setNbRes] = useState(0);
  const [mtRes, setMtRes] = useState(0);

  useEffect(() => {
    async function fetchData() {
      let avg =
        hotelTotalAmount === 0 ? 0 : (zooTotalAmount * 100) / hotelTotalAmount;
      setTotal(avg);
    }
    fetchData();
  }, [zooTotalAmount, hotelTotalAmount]);

  return (
    <RootStyle>
      <Typography variant="h4" sx={{ opacity: 0.72, paddingBottom: 5 }}>
        Ratio CA Hôtel/Parc-Zoo
      </Typography>
      <IconWrapperStyle>
        <Icon icon={windowsFilled} width={24} height={24} />
      </IconWrapperStyle>
      {isBusy ? (
        <CircularProgress />
      ) : (
        <>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              paddingLeft: 2,
              paddingRight: 2,
              justifyContent: "space-between",
            }}
          >
            <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
              Chiffre Affaire Zoo/Hôtel (%):{" "}
            </Typography>
            <Typography variant="subtitle2">{fShortenNumber(total)}</Typography>
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              paddingLeft: 2,
              paddingRight: 2,
              justifyContent: "space-between",
            }}
          >
            <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
              ---{" "}
            </Typography>
            <Typography variant="subtitle2">{"-"}</Typography>
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              paddingLeft: 2,
              paddingRight: 2,
              justifyContent: "space-between",
            }}
          >
            <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
              ---{" "}
            </Typography>
            <Typography variant="subtitle2">{"-"}</Typography>
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              paddingLeft: 2,
              paddingRight: 2,
              justifyContent: "space-between",
            }}
          >
            <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
              ---{" "}
            </Typography>
            <Typography variant="subtitle2">
              {"-"}
            </Typography>
          </Stack>

          <Stack
            direction="row"
            spacing={2}
            sx={{
              paddingLeft: 2,
              paddingRight: 2,
              justifyContent: "space-between",
            }}
          >
            <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
              ---{" "}
            </Typography>
            <Typography variant="subtitle2">{"-"}</Typography>
          </Stack>
        </>
      )}
    </RootStyle>
  );
}
