import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
import { Box, Card, Link, Container, Typography } from '@material-ui/core';
// layouts
import AuthLayout from '../../layouts/AuthLayout';
// components
import Page from '../../components/Page';
import CreateEmployeeForm from '../../components/_dashboard/employees/create/CreateEmployeeForm';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

// ----------------------------------------------------------------------

export default function Register() {
  return (
    <RootStyle title="Enregistrer Employé | Minimal-UI">
      <Container>
        <CreateEmployeeForm />
      </Container>
    </RootStyle>
  );
}
