import * as Yup from "yup";
import { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import { format } from "date-fns";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import {
  LoadingButton,
  LocalizationProvider,
  MobileDatePicker,
} from "@material-ui/lab";
import { Box, Grid, MenuItem, Switch } from "@mui/material";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import React from "react";

// ----------------------------------------------------------------------

const HOURS = [
  { label: "00:00", value: "00:00" },
  { label: "01:00", value: "01:00" },
  { label: "02:00", value: "02:00" },
  { label: "03:00", value: "03:00" },
  { label: "04:00", value: "04:00" },
  { label: "05:00", value: "05:00" },
  { label: "06:00", value: "06:00" },
  { label: "07:00", value: "07:00" },
  { label: "08:00", value: "08:00" },
  { label: "09:00", value: "09:00" },
  { label: "10:00", value: "10:00" },
  { label: "11:00", value: "11:00" },
  { label: "12:00", value: "12:00" },
  { label: "13:00", value: "13:00" },
  { label: "14:00", value: "14:00" },
  { label: "15:00", value: "15:00" },
  { label: "16:00", value: "16:00" },
  { label: "17:00", value: "17:00" },
  { label: "18:00", value: "18:00" },
  { label: "19:00", value: "19:00" },
  { label: "20:00", value: "20:00" },
  { label: "21:00", value: "21:00" },
  { label: "22:00", value: "22:00" },
  { label: "23:00", value: "23:00" },
];

const PAY_METHODS = [
  { label: "TMONEY", value: "TMONEY" },
  { label: "FLOOZ", value: "FLOOZ" },
];

export default function BookingForm({
  handleLogin,
  serverErrors,
  isLoading,
  pricePerAdult,
  pricePerChild,
}) {
  const [showPassword, setShowPassword] = useState(false);
  const [hrDate, setHrDate] = useState(new Date());

  const [checked, setChecked] = useState(true);

  const switchHandler = (event) => {
    setChecked(event.target.checked);
  };

  const FieldsSchema = Yup.object().shape({
    lastname: Yup.string().required("Saisir le nom de famille"),
    firstname: Yup.string().required("Saisir le(s) prénom(s)"),
    email: Yup.string()
      .email("Email non valide!")
      .required("Saisir une adresse email"),
    phone: Yup.string().required("Saisir un numero de telephone"),
    nbadults: Yup.number().when("bookingtype", {
      is: "zoo",
      then: Yup.number().min(1, "Saisir au minimum 01 adulte!"),
    }),
    nbchildren: Yup.number()
      .min(0, "Saisir au minimum 0 enfant!")
      .required(`Saisir le nombre d'enfant`),
    network: Yup.string().required("Veuillez selectionner le moyen de paiement"),
    bookingstarthour: Yup.string().required("Veuillez selectionner l'heure de la visite"),
  });

  const formik = useFormik({
    initialValues: {
      bookingtype: "zoo",
      lastname: "",
      firstname: "",
      bookingstartdate: "",
      bookingenddate: "",
      bookingstarthour: "",
      email: "",
      phone: "",
      nbadults: 0,
      nbchildren: 0,
      needresto: false,
      nbstudios: 0,
      nbconfrooms: 0,
      totaladults: 0,
      totalchildren: 0,
      totalzoo: 0,
      payphonenumber: "",
      network: "",
    },
    validationSchema: FieldsSchema,
    onSubmit: (values) => {
      const dt = format(hrDate, "yyyy-MM-dd");
      values.bookingstartdate = dt;
      values.needresto = checked;
      values.payphonenumber = values.phone;
      values.network = values.network;
      handleLogin(values);
    },
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;

  const handleDateChange = (date) => {
    setHrDate(date);
  };

  //calcul automatique du montant total des adultes
  const handleChangeTA = (event) => {
    let totAdult = event.target.value * pricePerAdult;
    let totZoo = formik.values.totalchildren + totAdult;
    setFieldValue("totaladults", totAdult);
    setFieldValue("nbadults", event.target.value);
    setFieldValue("totalzoo", totZoo); //recalcul du total global
  };

  // calcul automatique du montant total des enfants
  const handleChangeTC = (event) => {
    let totChild = event.target.value * pricePerChild;
    let totZoo = formik.values.totaladults + totChild;
    setFieldValue("totalchildren", totChild);
    setFieldValue("nbchildren", event.target.value);
    setFieldValue("totalzoo", totZoo); //recalcul du total global
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        {serverErrors && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            paddingBottom={5}
          >
            <Typography sx={{ color: "red" }}>{serverErrors}</Typography>
          </Stack>
        )}

        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <TextField
              size="small"
              variant="filled"
              fullWidth
              label="Nom"
              disabled={isLoading}
              {...getFieldProps("lastname")}
              error={Boolean(touched.lastname && errors.lastname)}
              helperText={touched.lastname && errors.lastname}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              variant="filled"
              fullWidth
              autoComplete="firstname"
              label="Prénoms"
              disabled={isLoading}
              {...getFieldProps("firstname")}
              error={Boolean(touched.firstname && errors.firstname)}
              helperText={touched.firstname && errors.firstname}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              size="small"
              variant="filled"
              fullWidth
              autoComplete="phone"
              type="text"
              label="Téléphone"
              disabled={isLoading}
              {...getFieldProps("phone")}
              error={Boolean(touched.phone && errors.phone)}
              helperText={touched.phone && errors.phone}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              size="small"
              variant="filled"
              fullWidth
              select
              label="Moyen Paiement"
              disabled={isLoading}
              error={Boolean(touched.network && errors.network)}
              helperText={touched.network && errors.network}
              {...getFieldProps("network")}
            >
              {PAY_METHODS.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              variant="filled"
              fullWidth
              autoComplete="email"
              type="email"
              label="Adresse Email"
              disabled={isLoading}
              {...getFieldProps("email")}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
          </Grid>

          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDatePicker
                id="date"
                label="Date"
                inputFormat="dd/MM/yyyy"
                value={hrDate}
                disabled={isLoading}
                onChange={handleDateChange}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    variant="filled"
                    {...params}
                    fullWidth
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              variant="filled"
              fullWidth
              select
              label="Heure de la visite"
              disabled={isLoading}
              {...getFieldProps("bookingstarthour")}
              helperText={touched.bookingstarthour && errors.bookingstarthour}
            >
              {HOURS.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={4}>
            <TextField
              size="small"
              variant="filled"
              fullWidth
              type="number"
              label="NB. Adultes"
              disabled={isLoading}
              {...getFieldProps("nbadults")}
              onChange={(event) => {
                handleChangeTA(event);
              }}
              error={Boolean(touched.nbadults && errors.nbadults)}
              helperText={touched.nbadults && errors.nbadults}
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              size="small"
              variant="filled"
              fullWidth
              type="number"
              label="Coût total pour les adultes (FCFA)"
              disabled={true}
              {...getFieldProps("totaladults")}
              value={formik.values.totaladults}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              size="small"
              variant="filled"
              fullWidth
              type="number"
              label="NB. enfants"
              disabled={isLoading}
              {...getFieldProps("nbchildren")}
              onChange={(event) => {
                handleChangeTC(event);
              }}
              error={Boolean(touched.nbchildren && errors.nbchildren)}
              helperText={touched.nbchildren && errors.nbchildren}
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              size="small"
              variant="filled"
              fullWidth
              type="number"
              label="Coût total pour les enfants (FCFA)"
              disabled={true}
              {...getFieldProps("totalchildren")}
              value={formik.values.totalchildren}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              size="small"
              variant="filled"
              fullWidth
              type="number"
              label="Montant total de la réservation"
              disabled={true}
              {...getFieldProps("totalzoo")}
              value={formik.values.totalzoo}
            />
          </Grid>
          <Grid item xs={8}>
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  checked={checked}
                  onChange={switchHandler}
                />
              }
              label={
                <Typography
                  variant="body2"
                  gutterBottom
                  sx={{ alignItems: "bottom" }}
                >
                  Cocher si possible restauration
                </Typography>
              }
            />
          </Grid>
        </Grid>

        <Grid
          container
          paddingTop={4}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          justifyContent="flex-end"
        >
          <Grid item xs={3}>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isLoading}
            >
              Envoyer
            </LoadingButton>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
