import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// material
import { styled } from "@material-ui/core/styles";
import { Box, Card, Stack, Container, Typography } from "@material-ui/core";
// layouts
import AuthLayout from "../../layouts/AuthLayout";
// components
import Page from "../../components/Page";
import CreateForm from "../../components/_dashboard/dispenses/create/CreateDispenseForm";
import DispenseLineService from "../../services/dispenseline.service";
import DispenseService from "../../services/dispense.service";
import EventBus from "../../services/common/EventBus";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

// ----------------------------------------------------------------------

export default function Create() {
  const [serverErrors, setServerErrors] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [lines, setLines] = useState([]);
  const navigate = useNavigate();

  const handleSave = ({ idl, dispenseDate, amount, description }) => {
    setIsLoading(true);
    setServerErrors("");
    DispenseService.save(idl, dispenseDate, amount, description)
      .then(
        () => {
          navigate("/dashboard/accounting/dispenses");
          //window.location.reload();
        },
        (error) => {
          let resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setServerErrors(resMessage);
          setIsLoading(false);
        }
      )
      .catch(() => setIsLoading(false));
  };

  const retrieveLines = () => {
    const params = {
      size: 100,
      sort: "label,asc",
    };
    DispenseLineService.getList(params)
      .then(
        (response) => {
          const { data } = response.data;

          setLines(data);

          console.log(response.data);
        },
        (error) => {
          if (error.response && error.response.status === 403) {
            EventBus.dispatch("logout");
          }
        }
      )
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(retrieveLines, []);

  return (
    <RootStyle title="Enregistrer Dépense">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Saisie de dépense
          </Typography>
        </Stack>
        <CreateForm
          handleSave={handleSave}
          serverErrors={serverErrors}
          isLoading={isLoading}
          lines={lines}
        />
      </Container>
    </RootStyle>
  );
}
