import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
// material
import { styled } from "@material-ui/core/styles";
import { Box, Card, Link, Container, Typography } from "@material-ui/core";
// layouts
import AuthLayout from "../../layouts/AuthLayout";
// components
import Page from "../../components/Page";
import CreateForm from "../../components/_dashboard/bookings/create/CreateBookingHForm";
import BookingsService from "src/services/bookings.service";
import { GlobalContext } from "../../context/Provider";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

// ----------------------------------------------------------------------

export default function CreateH() {
  const [serverErrors, setServerErrors] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSave = (values) => {
    setIsLoading(true);
    setServerErrors("");
    //alert(visit_date + " - " + tx_reference);
    BookingsService.terminate(values.visit_date, values.tx_reference, "RES-H")
      .then(
        () => {
          //mettre a jour le stock virtuel a afficher
          navigate("/dashboard/bookingshotel");
          //window.location.reload();
        },
        (error) => {
          let resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setServerErrors(resMessage);
          setIsLoading(false);
        }
      )
      .catch(() => setIsLoading(false));
  };

  return (
    <RootStyle title="Terminer une réservation">
      <Container>
        <CreateForm
          handleSave={handleSave}
          serverErrors={serverErrors}
          isLoading={isLoading}
        />
      </Container>
    </RootStyle>
  );
}
