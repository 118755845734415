import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// material
import { styled } from "@material-ui/core/styles";
import { Box, Card, Stack, Container, Typography } from "@material-ui/core";
// layouts
import AuthLayout from "../../layouts/AuthLayout";
// components
import Page from "../../components/Page";
import CreateForm from "../../components/_dashboard/deliverynotes/create/CreateDeliveryNoteForm";
import DeliveryNoteService from "../../services/deliverynote.service";
import VendorService from "../../services/vendor.service";
import EventBus from "../../services/common/EventBus";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

// ----------------------------------------------------------------------

export default function Register() {
  const [serverErrors, setServerErrors] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const navigate = useNavigate();

  const handleSave = ({ idv, noteDate, qty }) => {
    setIsLoading(true);
    setServerErrors("");
    DeliveryNoteService.save(idv, noteDate, qty)
      .then(
        () => {
          navigate("/dashboard/stocks/bons");
          //window.location.reload();
        },
        (error) => {
          let resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setServerErrors(resMessage);
          setIsLoading(false);
        }
      )
      .catch(() => setIsLoading(false));
  };

  const retrieveDrivers = () => {
    const params = {
      size: 100,
      sort: "lastName,asc",
    };
    VendorService.getList(params)
      .then(
        (response) => {
          const { data } = response.data;

          setDrivers(data);

          console.log(response.data);
        },
        (error) => {
          if (error.response && error.response.status === 403) {
            EventBus.dispatch("logout");
          }
        }
      )
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(retrieveDrivers, []);

  return (
    <RootStyle title="Enregistrer Bon de sortie | Minimal-UI">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Emission de bon de sortie
          </Typography>
        </Stack>
        <CreateForm
          handleSave={handleSave}
          serverErrors={serverErrors}
          isLoading={isLoading}
          drivers={drivers}
        />
      </Container>
    </RootStyle>
  );
}
