import { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
// material
import { styled } from "@material-ui/core/styles";
import { Box, Card, Link, Container, Typography } from "@material-ui/core";
// layouts
import AuthLayout from "../../layouts/AuthLayout";
// components
import Page from "../../components/Page";
import UpdateForm from "../../components/_dashboard/users/create/UpdateRolesForm";
import UserService from "../../services/common/user.service";
import ParamService from "../../services/param.service";
import eventBus from "src/services/common/EventBus";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

// ----------------------------------------------------------------------

export default function UpdateRoles(props) {
  const [serverErrors, setServerErrors] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isbusy, setIsbusy] = useState(false);
  const [availableRoles, setAvailableRoles] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const [chipData, setChipData] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();

  console.log(JSON.stringify(id));

  const handleSave = () => {
    setIsLoading(true);
    setServerErrors("");
    let roles = chipData.map((c) => c.label);
    UserService.changeRoles(id, roles)
      .then(
        () => {
          navigate("/dashboard/users");
        },
        (error) => {
          let resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setServerErrors(resMessage);
          setIsLoading(false);
        }
      )
      .catch(() => setIsLoading(false));
  };

  const retrieveUserData = () => {
    setIsbusy(true);
    UserService.getUser(id)
      .then(
        (response) => {
          let cData = response.data.data.roles.map((role, index) => ({
            id: index,
            label: role,
          }));
          setCurrentUser(response.data.data);
          setChipData(cData);
          setIsbusy(false);
        },
        (error) => {
          if (error.response && error.response.status === 403) {
            eventBus.dispatch("logout");
          }
        }
      )
      .catch((e) => {
        setIsbusy(false);
        console.log(e);
      });
  };

  const retrieveParamData = () => {
    setIsbusy(true);
    ParamService.getList()
      .then(
        (response) => {
          console.log(response.data);
          let cData = response.data.roles
            .map((role, index) => ({
              id: index,
              label: role,
            }))
            .filter((d) => d.label !== "ROLE_ADMIN");
          setAvailableRoles(cData);
          setIsbusy(false);
        },
        (error) => {
          if (error.response && error.response.status === 403) {
            eventBus.dispatch("logout");
          }
        }
      )
      .catch((e) => {
        setIsbusy(false);
        console.log(e);
      });
  };

  useEffect(retrieveUserData, [id]);
  useEffect(retrieveParamData, []);

  return (
    <RootStyle title="Gestion des profils">
      <Container>
        <UpdateForm
          handleSave={handleSave}
          serverErrors={serverErrors}
          isLoading={isLoading}
          // id={id}
          availableRoles={availableRoles}
          currentUser={currentUser}
          chipData={chipData}
          setChipData={setChipData}
          isbusy={isbusy}
        />
      </Container>
    </RootStyle>
  );
}
