import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// material
import { styled } from "@material-ui/core/styles";
import { Box, Card, Link, Container, Typography } from "@material-ui/core";
// layouts
import AuthLayout from "../../layouts/AuthLayout";
// components
import Page from "../../components/Page";
import CreateForm from "../../components/_dashboard/productions/update/UpdateProductionForm";
import ProductionService from "../../services/production.service";
import { GlobalContext } from "../../context/Provider";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

// ----------------------------------------------------------------------

export default function Update() {
  const [serverErrors, setServerErrors] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const navigate = useNavigate();
  const { availableStore, setAvailableStore } = useContext(GlobalContext);
  const { id } = useParams();

  const handleSave = (productionDate, qty) => {
    setIsLoading(true);
    setServerErrors("");
    ProductionService.update(id, productionDate, qty)
      .then(
        () => {
          //mettre a jour le stock virtuel a afficher
          let init = availableStore + qty;
          setAvailableStore(init);
          navigate("/dashboard/productions");
          //window.location.reload();
        },
        (error) => {
          let resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setServerErrors(resMessage);
          setIsLoading(false);
        }
      )
      .catch(() => setIsLoading(false));
  };

  const loadOne = () => {
    setIsLoading(true);
    setServerErrors("");
    ProductionService.getOne(id)
      .then(
        (response) => {
          console.log(JSON.stringify(response.data));
          setData(response.data);
          setIsLoading(false);
        },
        (error) => {
          let resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setServerErrors(resMessage);
          setIsLoading(false);
        }
      )
      .catch(() => setIsLoading(false));
  };

  useEffect(loadOne, [id]);

  return (
    <RootStyle title="Modifier Production">
      <Container>
        <CreateForm
          handleSave={handleSave}
          serverErrors={serverErrors}
          isLoading={isLoading}
          data={data}
        />
      </Container>
    </RootStyle>
  );
}
