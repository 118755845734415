import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
// material
import { styled } from "@material-ui/core/styles";
import { Box, Card, Link, Container, Typography } from "@material-ui/core";
// layouts
import AuthLayout from "../../layouts/AuthLayout";
// components
import Page from "../../components/Page";
import CreateForm from "../../components/_dashboard/productions/create/CreateProductionForm";
import ProductionService from "../../services/production.service";
import { GlobalContext } from "../../context/Provider";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

// ----------------------------------------------------------------------

export default function Create() {
  const [serverErrors, setServerErrors] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const {availableStore, setAvailableStore } = useContext(GlobalContext);

  const handleSave = (productionDate, qty) => {
    setIsLoading(true);
    setServerErrors("");
    ProductionService.save(productionDate, qty)
      .then(
        () => {
          //mettre a jour le stock virtuel a afficher
          let init = availableStore + qty;
          setAvailableStore(init);
          navigate("/dashboard/productions");
          //window.location.reload();
        },
        (error) => {
          let resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setServerErrors(resMessage);
          setIsLoading(false);
        }
      )
      .catch(() => setIsLoading(false));
  };

  return (
    <RootStyle title="Enregistrer Employé | Minimal-UI">
      <Container>
        <CreateForm
          handleSave={handleSave}
          serverErrors={serverErrors}
          isLoading={isLoading}
        />
      </Container>
    </RootStyle>
  );
}
