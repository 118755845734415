import * as Yup from "yup";
import React, { forwardRef, useState } from "react";
import { Icon } from "@iconify/react";
import { useFormik, Form, FormikProvider } from "formik";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
import { useNavigate } from "react-router-dom";
// material
import { Stack, TextField, Button, Typography, Box } from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import MobileDatePicker from "@material-ui/lab/MobileDatePicker";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import BusyModel from "../../app/AppBusyModal";

import { format, formatDistance, formatRelative, subDays } from "date-fns";
import Label from "../../../Label";
import { AddCircle, PlusOne } from "@mui/icons-material";

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

// ----------------------------------------------------------------------

//firstname, lastname, idcardnumber, birthdate, hireringdate

export default function UpdateRolesForm({
  handleSave,
  serverErrors,
  isLoading,
  availableRoles,
  currentUser,
  roles,
  chipData,
  setChipData,
  isbusy,
}) {
  const handleDelete = (chipToDelete) => () => {
    if (chipData.length > 1) {
      setChipData((chips) =>
        chips.filter((chip) => chip.label !== chipToDelete.label)
      );
    }
  };
  const handleClick = (chipToDelete) => () => {
    let data = chipData;
    if (data.some((c) => c.label === chipToDelete.label) === false) {
      data.push(chipToDelete);
    }
    let fake = { id: -1, label: "Fake" };
    setChipData(data.filter((chip) => chip.label !== fake.label));
  };

  const ShowRoles = (chips, ok) => {
    return (
      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          listStyle: "none",
          p: 0.5,
          m: 0,
        }}
        component="ul"
      >
        {chips.map((data) => {
          let icon;

          return (
            <ListItem key={data.key}>
              {ok ? (
                <Chip
                  icon={icon}
                  label={data.label}
                  color={"primary"}
                  onDelete={handleDelete(data)}
                />
              ) : (
                <Chip
                  icon={<AddCircle />}
                  label={data.label}
                  color={"secondary"}
                  onClick={handleClick(data)}
                />
              )}
            </ListItem>
          );
        })}
      </Paper>
    );
  };

  return (
    <form autoComplete="off" noValidate>
      {isbusy ? (
        <Box sx={{ display: "flex" }}>
          <BusyModel open={isbusy} />
        </Box>
      ) : (
        <Stack spacing={3}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              Profil utilisateur
            </Typography>
            {/* <Button
            variant="contained"
            component={RouterLink}
            to="create"
            startIcon={<Icon icon={plusFill} />}
          >
            Saisie vendeur
          </Button> */}
          </Stack>
          {serverErrors && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              paddingBottom={5}
            >
              <Typography sx={{ color: "red" }}>{serverErrors}</Typography>
            </Stack>
          )}
          <Stack direction={{ xs: "column", sm: "row" }} spacing={8}>
            <Stack direction={{ xs: "column", sm: "column" }} spacing={1}>
              <Typography sx={{ color: "text.primary" }}>Nom</Typography>
              <Typography
                variant="h5"
                sx={{ color: "text.primary" }}
              >{`${currentUser.lastName}`}</Typography>
            </Stack>
            <Stack direction={{ xs: "column", sm: "column" }} spacing={1}>
              <Typography sx={{ color: "text.primary" }}>Prénom(s)</Typography>
              <Typography
                variant="h5"
                sx={{ color: "text.primary" }}
              >{`${currentUser.firstName}`}</Typography>
            </Stack>
            <Stack direction={{ xs: "column", sm: "column" }} spacing={1}>
              <Typography sx={{ color: "text.primary" }}>Email</Typography>
              <Typography
                variant="h5"
                sx={{ color: "text.primary" }}
              >{`${currentUser.email}`}</Typography>
            </Stack>
          </Stack>

          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <Stack direction={{ xs: "column", sm: "column" }} spacing={1}>
              <Typography sx={{ color: "text.primary" }}>
                Roles courants de l'utlisateur
              </Typography>
              {currentUser.roles && ShowRoles(chipData, true)}
            </Stack>
          </Stack>

          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <Stack direction={{ xs: "column", sm: "column" }} spacing={1}>
              <Typography sx={{ color: "text.primary" }}>
                _______________________________________________
              </Typography>
            </Stack>
          </Stack>

          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <Stack direction={{ xs: "column", sm: "column" }} spacing={1}>
              <Typography sx={{ color: "text.primary" }}>
                Roles disponibles (cliquer pour en ajouter à ceux de
                l'utilisateur)
              </Typography>
              {availableRoles && ShowRoles(availableRoles, false)}
            </Stack>
          </Stack>

          <Grid
            container
            paddingTop={3}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            justifyContent="flex-end"
          >
            <Grid item>
              <LoadingButton
                fullWidth
                size="large"
                onClick={handleSave}
                variant="contained"
                loading={isLoading}
              >
                Enregistrer
              </LoadingButton>
            </Grid>
          </Grid>
        </Stack>
      )}
    </form>
  );
}
