import { Icon } from "@iconify/react";
import pieChart2Fill from "@iconify/icons-eva/pie-chart-2-fill";
import bookFill from "@iconify/icons-eva/book-fill";
import shoppingBagFill from "@iconify/icons-eva/shopping-bag-fill";
import homeFill from "@iconify/icons-eva/home-fill";
import setting2Fill from "@iconify/icons-eva/settings-2-fill";
import undoFill from "@iconify/icons-eva/undo-fill";
import alertTriangleFill from "@iconify/icons-eva/shopping-cart-fill";
import carFill from "@iconify/icons-eva/car-fill";
import pChar from "@iconify/icons-eva/pie-chart-fill";
//shopping-cart-outline

import AuthService from "../../services/common/auth.service";

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: "dashboard",
    path: "/dashboard/app",
    icon: getIcon(pieChart2Fill),
  },
  {
    title: "Réservations Parc/Zoo",
    path: "/dashboard/bookings",
    icon: getIcon(bookFill),
  },
  {
    title: "Réservations Hôtel",
    path: "/dashboard/bookingshotel",
    icon: getIcon(homeFill),
  },
  {
    title: "Prix Visites Parc/Zoo",
    path: "/dashboard/settingzprices",
    icon: getIcon(setting2Fill),
  },
];

export default sidebarConfig;
