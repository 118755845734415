import React, { useState, useEffect } from "react";

import { Icon } from "@iconify/react";
import bugFilled from "@iconify/icons-ant-design/dollar-circle-filled";
// material
import { alpha, styled } from "@material-ui/core/styles";
import { Card, Typography, CircularProgress } from "@material-ui/core";
// utils
import { fShortenNumber } from "../../../utils/formatNumber";
import PaymentService from "../../../services/payment.service";
import EventBus from "../../../services/common/EventBus";
import AuthService from "src/services/common/auth.service";
import Label from "src/components/Label";

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  textAlign: "center",
  padding: theme.spacing(5, 0),
  color: theme.palette.error.darker,
  backgroundColor: theme.palette.error.lighter,
}));

const IconWrapperStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: "center",
  marginBottom: theme.spacing(3),
  color: theme.palette.error.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(
    theme.palette.error.dark,
    0
  )} 0%, ${alpha(theme.palette.error.dark, 0.24)} 100%)`,
}));

// ----------------------------------------------------------------------

const TOTAL = 2304;

export default function AppMonthlyPayements({ start, end }) {
  const [total, setTotal] = useState(TOTAL);
  const [isBusy, setIsbusy] = useState(false);

  const retrieveData = () => {
    setIsbusy(true);

    const params = {
      start,
      end,
      page: 0,
      size: 10000000,
    };

    PaymentService.getList(params)
      .then(
        (response) => {
          const { data } = response.data;
          let tot = 0;
          if (data !== undefined) {
            data.forEach((p) => {
              tot = tot + p.amountPaid;
            });
          }
          setTotal(tot);
          setIsbusy(false);
        },
        (error) => {
          if (error.response && error.response.status === 403) {
            EventBus.dispatch("logout");
          }
        }
      )
      .catch((e) => {
        setIsbusy(false);
        console.log(e);
      });
  };

  useEffect(retrieveData, [start, end]);

  return (
    <RootStyle>
      <IconWrapperStyle>
        <Icon icon={bugFilled} width={24} height={24} />
      </IconWrapperStyle>
      {AuthService.hasRole("ROLE_RECOUVREMENT") ||
      AuthService.hasRole("ROLE_ADMIN") ||
      AuthService.hasRole("ROLE_MANAGER") ||
      AuthService.hasRole("ROLE_SUPERVISEUR") ? (
        isBusy ? (
          <CircularProgress />
        ) : (
          <Typography variant="h4">{fShortenNumber(total)}</Typography>
        )
      ) : (
        <Label variant="ghost" color="error">
          Accès restreint!!!
        </Label>
      )}
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        Montant recouvré
      </Typography>
    </RootStyle>
  );
}
