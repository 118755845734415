import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import searchFill from "@iconify/icons-eva/search-fill";
import trash2Fill from "@iconify/icons-eva/trash-2-fill";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import MobileDatePicker from "@material-ui/lab/MobileDatePicker";
import { format } from "date-fns";
// material
import { styled } from "@material-ui/core/styles";
import {
  Box,
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  Stack,
  MenuItem,
} from "@material-ui/core";
import { useState } from "react";
import { TextField } from "@mui/material";

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": { width: 320, boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

ActionsListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
};

export default function ActionsListToolbar({
  numSelected,
  filterName,
  onFilterName,
  setStart,
  setEnd,
  setAction,
  actions,
}) {
  let stDt = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  let enDt = new Date(new Date());
  enDt.setHours(23);
  enDt.setMinutes(59);
  enDt.setSeconds(59);
  const [startDate, setStartDate] = useState(stDt);
  const [endDate, setEndDate] = useState(enDt);

  const handleSDateChange = (date) => {
    setStartDate(date);
    setStart(format(date, "dd-MM-yyyy HH:mm:ss"));
  };
  const handleEDateChange = (date) => {
    setEndDate(date);
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    setEnd(format(date, "dd-MM-yyyy HH:mm:ss"));
  };

  const handleChange = (event) => {
    setAction(event.target.value);
  };

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: "primary.main",
          bgcolor: "primary.lighter",
        }),
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        paddingBottom={2}
      >
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileDatePicker
              label="Date début"
              inputFormat="dd/MM/yyyy"
              value={startDate}
              onChange={handleSDateChange}
              renderInput={(params) => (
                <TextField {...params} variant="standard" />
              )}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileDatePicker
              label="Date fin"
              inputFormat="dd/MM/yyyy"
              value={endDate}
              onChange={handleEDateChange}
              renderInput={(params) => (
                <TextField {...params} variant="standard" />
              )}
            />
          </LocalizationProvider>

          <TextField
            id="outlined-select-currency"
            fullWidth
            select
            label="Type d'action"
            onChange={handleChange}
          >
            <MenuItem value={0}>Tous</MenuItem>
            {actions &&
              actions.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
          </TextField>
        </Stack>
      </Stack>
    </RootStyle>
  );
}
