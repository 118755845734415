import React from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import axios from "axios";
// material
import { styled } from "@material-ui/core/styles";
import {
  Card,
  Stack,
  Link,
  Container,
  Typography,
  Grid,
}
from "@material-ui/core";
// layouts
import AuthLayout from "../../layouts/AuthLayout";
// components
import Page from "../../components/Page";
import { MHidden } from "../../components/@material-extend";
// import AuthSocial from "../components/authentication/AuthSocial";
// import AuthService from "../services/common/auth.service";
import { useState } from "react";
import { APP_DESC } from "../../utils/appConstants";
import sideimage from "../../img/logo.png"; // Tell webpack this JS file uses this image
import { BookingHotelForm } from "../../components/_dashboard/booking";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import BookingsService from "../../services/bookings.service";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

const API_KEY = process.env.REACT_APP_PAYGATE_API_KEY;
const RETURN_URL = `https://www.talinnaminizootogo.com`;
const API_URL_PAGE = `https://www.paygateglobal.com/v1/page`;
const API_URL_PAY = `https://paygateglobal.com/api/v1/pay`;
const RECEIPT_URL = `https://main.d3lwd2e2axx4x7.amplifyapp.com/ticket`;
// ----------------------------------------------------------------------

export default function BookingHotel() {
  const [serverErrors, setServerErrors] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [pricePerAdult, setPricePerAdult] = React.useState(0);
  const [pricePerChild, setPricePerChild] = React.useState(0);
  const [roomTypes, setRoomTypes] = React.useState([]);

  const getItem = async (params, desc) => {

    BookingsService.getItem(params)
      .then(response => {

        console.log(JSON.stringify(response));
        
        if (response.data.tx_reference !== "") {
          let returnUrl = `${RECEIPT_URL}?tx_reference=${response.data.payment_reference}&amount=${response.data.amount}&label=${desc}&phone_number=${response.data.phone_number}`

          window.location.replace(returnUrl);
        }

      });
  };
  
  const pauseVerify = async (params, url) => new Promise((resolve, reject) => {
    var count = 0;
    var interval = setInterval(async () => {
        var res = await getItem(params, url);
        count += 1;

        if (count === 4) { // if it has been run 4 times, we resolve the promise
            clearInterval(interval);
            resolve(res); // result of promise
        }
        console.log("Execution "+count);
    }, 1000 * 15); // 15 seconds interval
  });

  const handleLogin = (values) => {
    setIsLoading(true);
    setServerErrors("");

    BookingsService.save(values)
      .then(
        (response) => {
          let body = JSON.parse(response.data.body);
          let sk = body.bookingstartdate.replace("#", "");
          
          // Composer un token (5 caracteres) pour tracer le parametre concerné dans la table talinna-params
          // idkey=ZOO-PRICE=>ZP ===> sortKey=ZOO-PRICE#1 => final ==> ZP001
          // idKey=ROOM-TYPE=>RT ===> sortKey=ROOM-TYPE#Chambre Standard => final ==> RT001
          // idKey=ROOM-TYPE=>RT ===> sortKey=ROOM-TYPE#Suite => final ==> RT002
          let paramToken="RT002";
          if(body.sortKey === "ROOM-TYPE#Chambre Standard"){
            paramToken="RT001";
          }

          // Composer le keys avec 3 clés
          // Rappel: veiller à les dechiffrer dans le lambda de validation!!!(validate-booking-function)
          let keys = body.identifier+""+ paramToken + "" + sk;
          //let keys = body.identifier + "" + sk;
          //let keys = new Date().getTime();

          //console.log("Body >> "+JSON.stringify(body));
          let description = `Rés. ${values.nbdays} x ${values.roomType}`;
          // let rUrl = `${API_URL_PAGE}/?token=${API_KEY}&amount=${values.totalzoo}&description=${description}&identifier=${keys}&url=${returnUrl}&phone=${values.payphonenumber}&network=${values.network}`;

          const payload = {
            "phone_number": values.payphonenumber,
            "amount": values.totalzoo,
            "description": description,
            "identifier": keys,
            "network": values.network
          }
          //Lancer l'appel du paiement sur le Mobile Money PayGate pour initier la validation
          // du paiement par le client
          //console.log("Payload >> "+JSON.stringify(payload));
          BookingsService.pay(payload)
            .then(async (result) => {
              console.log(JSON.stringify(result));
              
             // if ((result.data.status * 1) === 0) {
                // Si le paiement a été validé par le client
                let params = {
                  pk: "RES-H",
                  sk: body.bookingstartdate,
                };
                
                //Tester si le paiement a été effectuée
                await pauseVerify(params, description);
               
                setServerErrors("Erreur lors de la validation du paiement!");
                setIsLoading(false);

              // }
              // else {
              //   setServerErrors("Echec de validation du paiement!");
              //   setIsLoading(false);
              // }
            })
            .catch(() => {
              setServerErrors("Echec d'operation, merci de réessayer plus tard!");
              setIsLoading(false);
            });
        },
        (error) => {
          alert(JSON.stringify(error));
          let resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setServerErrors(resMessage);
          setIsLoading(false);
        }
      )
      .catch(() => setIsLoading(false));
  };

  React.useEffect(() => {
    async function fetchData() {
      const params = { id: "ROOM-TYPE" };
      BookingsService.getParams(params)
        .then((response) => {
          setRoomTypes(response.data);
        })
        .catch((error) => console.log(JSON.stringify(error)));
    }
    fetchData();
  }, []);

  return (
    <RootStyle title="Réservation">
      <Header page={"hotel"} />

      <MHidden width="mdDown">
        <SectionStyle>
          <img src={sideimage} alt="login" />
          <Grid
            container
            sx={{ marginTop: 10}}
          >
            <Grid item xs={12} sx={{ justifyContent: "center" }}>
              <Typography
                variant="body"
                sx={{
                  px: 5,
                  mt: 10,
                  mb: 5,
                }}
              >
                Contacts
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ justifyContent: "center" }}>
              <Typography
                variant="body"
                sx={{
                  alignItems: "center",
                  alignContent: "center",
                  px: 5,
                  mt: 10,
                  mb: 5,
                }}
              >
                Tel: +228 98 47 87 87 / 91 64 33 33
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ justifyContent: "center" }}>
              <Typography
                variant="body"
                sx={{
                  alignItems: "center",
                  alignContent: "center",
                  px: 5,
                  mt: 10,
                  mb: 5,
                }}
              >
                Email : contact@talinnaminizootogo.com
              </Typography>
            </Grid>
          </Grid>
        </SectionStyle>
      </MHidden>

      <Container sx={{ marginTop: 10 }}>
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Réservation des chambres à TALI N'NA
            </Typography>
          </Stack>

          <BookingHotelForm
            handleLogin={handleLogin}
            serverErrors={serverErrors}
            isLoading={isLoading}
            roomTypes={roomTypes}
          />
        </ContentStyle>
      </Container>
      <Footer />
    </RootStyle>
  );
}
