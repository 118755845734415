import * as Yup from 'yup';
import React, { forwardRef, useState } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate } from 'react-router-dom';
// material
import { Stack, TextField, Button, InputAdornment } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import MobileDatePicker from '@material-ui/lab/MobileDatePicker';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Grid } from '@mui/material';
import { format, formatDistance, formatRelative, subDays } from 'date-fns';
import { uploadFile } from 'react-s3';

import EmployeeDataService from '../../../../services/EmployeeService';
import { s3Config } from '../../../../utils/s3Config';

import ImageUploader from './ImageUploader';

// ----------------------------------------------------------------------

export default function CreateEmployeeForm() {
  const navigate = useNavigate();
  const [hrDate, setHrDate] = useState(new Date());
  const [selectedFile, setSelectedFile] = useState(null);

  const handleUploadFile = async (file) => {
    uploadFile(file, s3Config)
      .then((data) => console.log(data))
      .catch((err) => console.error(err));
  };

  const CreateSchema = Yup.object().shape({
    firstName: Yup.string().min(2, 'Trop court!').max(50, 'Trop long!').required('Prénom requis'),
    lastName: Yup.string().min(2, 'Trop court!').max(50, 'Trop long!').required('Nom requis')
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      gender: 'ND',
      hiringDate: '',
      imageUrl: ''
    },
    validationSchema: CreateSchema,
    onSubmit: (values) => {

      // If Photo choosen, then upload image on s3 before call the API 
      // and save the provided url in the db through imageUrl
      // else juste call the API without upload in s3
      if (selectedFile !== null) {
        uploadFile(selectedFile, s3Config)
          .then((data) => {
            console.log(data);
            const dt = format(hrDate, 'dd-MM-yyyy');
            values.hiringDate = dt;
            values.imageUrl = data.location;
            EmployeeDataService.save(values)
              .then((response) => {
                navigate('/dashboard/employees');
              })
              .catch((e) => console.log(e));
          })
          .catch((err) => console.error(err));
      } else {
        const dt = format(hrDate, 'dd-MM-yyyy');
        values.hiringDate = dt;
        EmployeeDataService.save(values)
          .then((response) => {
            navigate('/dashboard/employees');
          })
          .catch((e) => console.log(e));
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  const handleDateChange = (date) => {
    setHrDate(date);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Nom"
              {...getFieldProps('lastName')}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Prénom"
              {...getFieldProps('firstName')}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />
          </Grid>
        </Grid>
        <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }} paddingTop={3}>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDatePicker
                label="Date embauche"
                inputFormat="dd/MM/yyyy"
                value={hrDate}
                onChange={handleDateChange}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Sexe</FormLabel>
              <RadioGroup
                row
                aria-label="gender"
                name="row-radio-buttons-group"
                {...getFieldProps('gender')}
              >
                <FormControlLabel value="F" control={<Radio />} label="F" />
                <FormControlLabel value="M" control={<Radio />} label="M" />
                <FormControlLabel value="ND" control={<Radio />} label="Autre" />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>

        <Grid
          container
          paddingTop={3}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          justifyContent="flex-start"
        >
          <Grid item>
            <ImageUploader selectedFile={selectedFile} setSelectedFile={setSelectedFile} />
          </Grid>
        </Grid>

        <Grid
          container
          paddingTop={3}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          justifyContent="flex-end"
        >
          <Grid item>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              
            >
              Enregistrer
            </LoadingButton>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
