import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// material
import { styled } from "@material-ui/core/styles";
import { Box, Card, Stack, Container, Typography } from "@material-ui/core";
// layouts
import AuthLayout from "../../layouts/AuthLayout";
// components
import Page from "../../components/Page";
import CreateForm from "../../components/_dashboard/storebacks/create/CreateStoreBackForm";
import VendorService from "../../services/vendor.service";
import StoreOutService from "../../services/storeout.service";
import StoreBackService from "../../services/storeback.service";
import EventBus from "../../services/common/EventBus";
import { GlobalContext } from "src/context/Provider";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

//{idvendor}/{idvehicle}/{idnote}
//dischargeNumber,outDate,qty,supQty,totalAmount
// ----------------------------------------------------------------------

export default function Create() {
  const [serverErrors, setServerErrors] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [stores, setStores] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [idv, setIdv] = useState(0);
  const [ids, setIds] = useState(0);
  const [isLoadingChild, setIsLoadingChild] = useState(false);
  const navigate = useNavigate();
  const { availableStore, setAvailableStore } = useContext(GlobalContext);

  const handleSave = ({
    idvendor,
    idstore,qty, amount, backDate, reason
  }) => {
    setIsLoading(true);
    setServerErrors("");

    StoreBackService.save(
      idstore, qty, amount, backDate, reason
    )
      .then(
        () => {
          let stk = availableStore + qty;
          setAvailableStore(stk);
          navigate("/dashboard/stocks/retours");
          //window.location.reload();
        },
        (error) => {
          let resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setServerErrors(resMessage);
          setIsLoading(false);
        }
      )
      .catch(() => setIsLoading(false));
  };

  const retrieveDrivers = () => {
    const params = {
      size: 100,
      sort: "lastName,asc",
    };
    setIsLoadingChild(true);
    VendorService.getList(params)
      .then(
        (response) => {
          const { data } = response.data;

          setDrivers(data);
          setIsLoadingChild(false);
          console.log(response.data);
        },
        (error) => {
          setIsLoadingChild(false);
          if (error.response && error.response.status === 403) {
            EventBus.dispatch("logout");
          }
        }
      )
      .catch((e) => {
        setIsLoadingChild(false);
        console.log(e);
      });
  };

  const retrieveStores = () => {
    const params = {
      id: idv,
      unpaid: true,
      size: 100,
    };
    setIsLoadingChild(true);
    StoreOutService.getList(params)
      .then(
        (response) => {
          const { data } = response.data;

          setStores(data);
          setIsLoadingChild(false);
          console.log(response.data);
        },
        (error) => {
          setIsLoadingChild(false);
          if (error.response && error.response.status === 403) {
            EventBus.dispatch("logout");
          }
        }
      )
      .catch((e) => {
        setIsLoadingChild(false);
        console.log(e);
      });
  };

  
  useEffect(retrieveDrivers, []);
  useEffect(retrieveStores, [idv]);

  return (
    <RootStyle title="Saisie de chargements">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Saisie de retour en stock
          </Typography>
        </Stack>
        <CreateForm
          handleSave={handleSave}
          serverErrors={serverErrors}
          isLoading={isLoading}
          drivers={drivers}
          stores={stores}
          setIdv={setIdv}
          isLoadingChild={isLoadingChild}
        />
      </Container>
    </RootStyle>
  );
}
