import React, { useState } from 'react';
import Input from '@mui/material/Input';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { InputLabel, FormControl, getDividerUtilityClass } from '@mui/material';

export default function ImageUploader({ selectedFile, setSelectedFile }) {
  const handleFileInput = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  return (
    <div>
      <label htmlFor="btn-upload" title="Choisir photo">
        <Input id="btn-upload" type="file" onChange={handleFileInput} />
        {selectedFile ? selectedFile.name : null}
      </label>
    </div>
  );
}
