import * as Yup from "yup";
import React, { forwardRef, useState } from "react";
import { Icon } from "@iconify/react";
import { useFormik, Form, FormikProvider } from "formik";
import { Link as RouterLink } from "react-router-dom";
// material
import { Stack, TextField, Button, Typography } from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import { Box, Grid } from "@mui/material";
import BusyModal from "../../../../components/_dashboard/app/AppBusyModal";

// ----------------------------------------------------------------------

export default function ZooPriceSettingForm({
  handleSave,
  serverErrors,
  isLoading,
  pricePerAdult,
  pricePerChild,
}) {
  const [hrDate, setHrDate] = useState(new Date());

  const CreateSchema = Yup.object().shape({
    pricePerAdult: Yup.number()
      .min(5, "Le prix pour adulte doit être supérieur à 5F!")
      .required("Le prix pour adulte requis! "),
    pricePerChild: Yup.number()
      .min(5, "Le prix pour enfant doit être supérieur à 5F!")
      .required("Le prix pour enfant requis! "),
  });

  const formik = useFormik({
    initialValues: {
      pricePerAdult: "5",
      pricePerChild: "5",
    },
    validationSchema: CreateSchema,
    onSubmit: (values) => {
      values.pricePerAdult = ""+values.pricePerAdult;
      values.pricePerChild = ""+values.pricePerChild;
      handleSave(values);
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
  } = formik;

  React.useEffect(() => {
    async function fetchData() {
      setFieldValue("pricePerAdult", pricePerAdult);
      setFieldValue("pricePerChild", pricePerChild);
    }
    fetchData();
  }, [pricePerAdult, pricePerChild]);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        {serverErrors && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            paddingBottom={5}
          >
            <Typography sx={{ color: "red" }}>{serverErrors}</Typography>
          </Stack>
        )}
        {isLoading && (
          <Box sx={{ display: "flex" }}>
            <BusyModal open={isLoading} />
          </Box>
        )}

<Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item md={4} sm={12} xs={12}>
              <TextField
                fullWidth
                variant="filled"
                label="Prix pour adulte"
                type="number"
                disabled={isLoading}
                {...getFieldProps("pricePerAdult")}
                error={Boolean(touched.pricePerAdult && errors.pricePerAdult)}
                helperText={touched.pricePerAdult && errors.pricePerAdult}
              />
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <TextField
                fullWidth
                variant="filled"
                label="Prix pour enfant (- de 18 ans)"
                type="number"
                disabled={isLoading}
                {...getFieldProps("pricePerChild")}
                error={Boolean(touched.pricePerChild && errors.pricePerChild)}
                helperText={touched.pricePerChild && errors.pricePerChild}
              />
            </Grid>
          </Grid>

        <Grid
          container
          paddingTop={3}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          justifyContent="flex-end"
        >
          <Grid item>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isLoading}
            >
              Enregistrer
            </LoadingButton>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
