import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
// material
import { styled } from "@material-ui/core/styles";
import { Box, Card, Link, Container, Typography } from "@material-ui/core";
// components
import Page from "../../../components/Page";
import CreateForm from "../../../components/_dashboard/bookings/settings/ZooPriceSettingForm";
import BookingsService from "../../../services/bookings.service";
import { Stack } from "@mui/material";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

// ----------------------------------------------------------------------

export default function ZooPriceSetting() {
  const [serverErrors, setServerErrors] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [pricePerAdult, setPricePerAdult] = React.useState(0);
  const [pricePerChild, setPricePerChild] = React.useState(0);
  const [pk, setPk] = React.useState("");
  const [sortKey, setSortKey] = React.useState("");
  const navigate = useNavigate();

  const handleSave = (values) => {
    setIsLoading(true);
    setServerErrors("");
    //alert(JSON.stringify(values));
    const params = {
      pk: pk,
      sk: sortKey,
    };
    BookingsService.updateParams(values, params)
      .then(
        () => {
          navigate("/dashboard");
        },
        (error) => {
          let resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setServerErrors(resMessage);
          setIsLoading(false);
        }
      )
      .catch((error) => setIsLoading(false));

  };

  React.useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      const params = { id: "ZOO-PRICE" };
      BookingsService.getParams(params)
        .then((response) => {
          let item = response.data;
          setPricePerAdult(item[0].zooPricePerAdult);
          setPricePerChild(item[0].zooPricePerChild);
          setPk(item[0].idKey);
          setSortKey(item[0].sortKey);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(JSON.stringify(error));
        });
    }
    fetchData();
  }, []);

  return (
    <RootStyle title="Terminer une réservation">
      <Container>
        <Stack sx={{ mb: 5, justifyContent: "center", alignItems: "center" }}>
          <Typography variant="h5" gutterBottom>
            Fixation de prix des visites du Parc/Zoo
          </Typography>
        </Stack>
        <CreateForm
          handleSave={handleSave}
          serverErrors={serverErrors}
          isLoading={isLoading}
          pricePerAdult={pricePerAdult}
          pricePerChild={pricePerChild}
        />
      </Container>
    </RootStyle>
  );
}
