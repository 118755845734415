import { filter } from "lodash";
import { Icon } from "@iconify/react";
import { sentenceCase } from "change-case";
import { useEffect, useState } from "react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Link as RouterLink } from "react-router-dom";
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress,
  Box,
} from "@material-ui/core";
import { format } from "date-fns";
// components
import Page from "../../components/Page";
import Label from "../../components/Label";
import Scrollbar from "../../components/Scrollbar";
import SearchNotFound from "../../components/SearchNotFound";
import {
  ListHead,
  ListToolbar,
  MoreMenu,
} from "../../components/_dashboard/bookings";
import BusyModal from "../../components/_dashboard/app/AppBusyModal";
import BookingService from "../../services/bookings.service";
import EventBus from "../../services/common/EventBus";

//
import USERLIST from "../../_mocks_/user";

// ----------------------------------------------------------------------
//tx_reference":"EEDDD","nbchildren":"1","payment_method":"FLOOZ","bookingstartdate":"2022-09-19","nbadults":"2","date_time":"222","nbstudios":0,"email":"kwilson@gmail.com","paid":true,"bookingstarthour":"06:00","lastname":"KOMLAN","needresto":true,"bookingtype":"zoo","firstname":"Wilson","phone_number":"90919091","amount":300,"bookingdate":1663610042762,"bookingenddate":"","id":"7ce27686-7fb4-48d6-b63f-79798d1adebd","nbconfrooms":0,"phone":"90919091","payment_reference":"XXSS"

const TABLE_HEAD = [
  { id: "startdate", label: "DATE RES. ", alignRight: false },
  { id: "lastname", label: "NOM & PRENOM(S)", alignRight: false },
  { id: "nbadults", label: "NB. ADULTES", alignRight: false },
  { id: "nbchildren", label: "NB. ENFTS", alignRight: false },
  { id: "tx_reference", label: "N° RES.", alignRight: false },
  { id: "status", label: "ETAT", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Bookings() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("desc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("bookingstartdate");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [isbusy, setIsbusy] = useState(false);
  const [start, setStart] = useState(
    format(
      new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      "yyyy-MM-dd"
    )
  );
  let enDt = new Date(new Date().getFullYear(), new Date().getMonth()+1, 0);

  const [end, setEnd] = useState(format(enDt, "yyyy-MM-dd"));

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setPageSize(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const filteredUsers = applySortFilter(
    USERLIST,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;

  const getRequestParams = (start, end, page, pageSize, sort) => {
    const params = {};

    if (start) {
      params.start = start;
    }

    if (end) {
      params.end = end;
    }

    if (page) {
      params.page = page;
    }

    if (pageSize) {
      params.size = pageSize;
    }

    if (sort) {
      params.sort = sort.concat("," + order);
    }
    params.idKey = "RES-Z";
    return params;
  };

  const retrieveData = () => {
    setIsbusy(true);
    const params = getRequestParams(start, end, page, pageSize, orderBy);
    BookingService.getList(params)
      .then(
        (response) => {
          //console.log(JSON.stringify(response.data));
          const { Items, count } = response.data;

          setData(response.data);
          setCount(response.data.length);
          setIsbusy(false);
        },
        (error) => {
          if (error.response && error.response.status === 403) {
            EventBus.dispatch("logout");
          }
        }
      )
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(retrieveData, [page, start, end, orderBy, pageSize, order, count]);

  return (
    <Page title="Réservations Parc/Zoo">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Liste des réservations
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="update"
            startIcon={<Icon icon={plusFill} />}
          >
            Terminer une réservation
          </Button>
        </Stack>

        <Card>
          <ListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            setStart={setStart}
            setEnd={setEnd}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={data === undefined ? 0 : data.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {isbusy ? (
                    <Box sx={{ display: "flex" }}>
                      <BusyModal open={isbusy} />
                    </Box>
                  ) : (
                    data !== undefined &&
                    data.map((row) => {
                      const {
                        bookingstartdate,
                        startdate,
                        lastname,
                        firstname,
                        nbadults,
                        nbchildren,
                        status,
                        tx_reference,
                      } = row;
                      const isItemSelected =
                        selected.indexOf(bookingstartdate) !== -1;

                      return (
                        <TableRow
                          hover
                          key={bookingstartdate}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell align="left">{startdate}</TableCell>
                          <TableCell align="left">{`${lastname} ${firstname}`}</TableCell>
                          <TableCell align="left">{nbadults}</TableCell>
                          <TableCell align="left">{nbchildren}</TableCell>
                          <TableCell align="left">{tx_reference}</TableCell>
                          <TableCell align="left">
                            {status === "PENDING" ? "EN COURS" : "TERMINEE"}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  )}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={count === undefined ? 0 : count}
            rowsPerPage={rowsPerPage === undefined ? 0 : rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
