import http from "./http-common";

const register = (email, password, firstName, lastName) => {
  return http
    .post("/auth/signup", {
      email,
      password,
      firstName,
      lastName,
    })
    .then((response) => {
      return response.data;
    });
};

const login = (email, password) => {
  return http
    .post("/auth/signin", {
      email,
      password,
    })
    .then((response) => {
      if (response.data.jwt) {
        localStorage.setItem("perfectuser", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("perfectuser");
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("perfectuser"));
};

const hasRole = (role) => {
  if (getCurrentUser() === undefined) {
    return false;
  } else if (getCurrentUser() === null) {
    return false;
  }
  return getCurrentUser().roles.some((r) => r === role);
};

export default {
  register,
  login,
  logout,
  getCurrentUser,
  hasRole,
};
