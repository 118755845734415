import { Icon } from "@iconify/react";
import androidFilled from "@iconify/icons-ant-design/android-filled";
// material
import { alpha, styled } from "@material-ui/core/styles";
import { Card, Typography, TextField, Stack } from "@material-ui/core";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import MobileDatePicker from "@material-ui/lab/MobileDatePicker";
import { format } from "date-fns";

// utils
import { fShortenNumber } from "../../../utils/formatNumber";
import { useState } from "react";

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  padding: theme.spacing(2, 0),
}));

// ----------------------------------------------------------------------

const TOTAL = 714000;

export default function AppPeriodSector({ setStart, setEnd }) {
  let stDt = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  let enDt = new Date(new Date().getFullYear(), new Date().getMonth()+1, 0);

  const [startDate, setStartDate] = useState(stDt);
  const [endDate, setEndDate] = useState(enDt);

  const handleSDateChange = (date) => {
    setStartDate(date);
    setStart(format(date, "yyyy-MM-dd"));
  };
  const handleEDateChange = (date) => {
    setEndDate(date);
    setEnd(format(date, "yyyy-MM-dd"));
  };

  return (
    <RootStyle>
      <Stack
        direction="column"
        paddingBottom={3}
        spacing={3}
      >
        <Stack direction={{ xs: "column", sm: "row"}}>
          <Typography variant="h4">Tableau de bord périodique</Typography>
        </Stack>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileDatePicker
              label="Date début"
              inputFormat="dd/MM/yyyy"
              value={startDate}
              onChange={handleSDateChange}
              renderInput={(params) => (
                <TextField {...params} variant="standard" />
              )}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileDatePicker
              label="Date fin"
              inputFormat="dd/MM/yyyy"
              value={endDate}
              onChange={handleEDateChange}
              renderInput={(params) => (
                <TextField {...params} variant="standard" />
              )}
            />
          </LocalizationProvider>
        </Stack>
      </Stack>
    </RootStyle>
  );
}
