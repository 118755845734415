import * as React from "react";
import Box from "@mui/material/Box";
import { Typography, Link } from "@mui/material";

export default function Footer() {
  return (
    <footer
      style={{
        backgroundColor: "#fe6519",
        color: "white",
        position: "fixed",
        bottom: 0,
        width: "100%",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: 20,
          backgroundColor: "#fe6519",
          display: 'flex',
          flexDirection: 'row',
          p: 5,
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Link href="https://www.talinnaminizootogo.com/" sx={{color: 'white'}} variant="body2">Aller au site web</Link>
        <Typography variant="body2">
          Adresse: Evou-Kodégbé, non loin de Témédja sur la route de Badou
        </Typography>
        <Link href="/dashboard" sx={{color: 'white'}} variant="body2">Administration</Link>
      </Box>
    </footer>
  );
}
