import React, { useState, useEffect } from "react";

import { merge } from "lodash";
import ReactApexChart from "react-apexcharts";
// material
import { useTheme, styled } from "@material-ui/core/styles";
import {
  Card,
  CardHeader,
  CircularProgress,
  Box,
  Typography,
  Stack,
} from "@material-ui/core";
// utils
import _ from "underscore";
import { fNumber } from "../../../utils/formatNumber";
//
import { BaseOptionChart } from "../../charts";
import StoreOutService from "../../../services/storeout.service";
import EventBus from "../../../services/common/EventBus";
import Label from "src/components/Label";
import AuthService from "src/services/common/auth.service";

// ----------------------------------------------------------------------

const CHART_HEIGHT = 372;
const LEGEND_HEIGHT = 72;

const ChartWrapperStyle = styled("div")(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  "& .apexcharts-canvas svg": { height: CHART_HEIGHT },
  "& .apexcharts-canvas svg,.apexcharts-canvas foreignObject": {
    overflow: "visible",
  },
  "& .apexcharts-legend": {
    height: LEGEND_HEIGHT,
    alignContent: "center",
    position: "relative !important",
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));

// ----------------------------------------------------------------------

const CHART_DATA = [];
const CHART_LABEL = [];

export default function AppCurrentDriversDebt({ start, end }) {
  const theme = useTheme();
  const [chartData, setChartData] = useState(CHART_DATA);
  const [chartLabel, setChartLabel] = useState(CHART_LABEL);
  const [isBusy, setIsbusy] = useState(false);
  const [total, setTotal] = useState(0);

  const chartOptions = merge(BaseOptionChart(), {
    colors: [
      theme.palette.primary.main,
      theme.palette.info.main,
      theme.palette.warning.main,
      theme.palette.error.main,
    ],
    labels: chartLabel,
    stroke: { colors: [theme.palette.background.paper] },
    legend: { floating: true, horizontalAlign: "left" },
    dataLabels: { enabled: true, dropShadow: { enabled: false } },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) => `${seriesName}`,
        },
      },
    },
    plotOptions: {
      pie: { donut: { labels: { show: false } } },
    },
  });

  const retrieveData = () => {
    setIsbusy(true);

    const params = {
      start,
      end,
      unpaid: true,
      page: 0,
      size: 100000,
    };

    StoreOutService.getList(params)
      .then(
        (response) => {
          const { drivers } = response.data;
          let cData = [];
          let cLabels = [];
          let tot = 0;
          if (drivers !== undefined) {
            for (const [key, value] of Object.entries(drivers)) {
              let valData = Object.values(value);
              let dbt = 0;
              let lbl = "";
              valData.forEach((v) => {
                dbt = dbt + v.amountToPay;
                lbl = v.vendor.lastName + " " + v.vendor.firstName;
              });
              cData.push(dbt);
              cLabels.push(lbl);
              tot = tot + dbt;
            }
          }

          setTotal(tot);
          setChartData(cData);
          setChartLabel(cLabels);
          setIsbusy(false);
        },
        (error) => {
          if (error.response && error.response.status === 403) {
            EventBus.dispatch("logout");
          }
        }
      )
      .catch((e) => {
        setIsbusy(false);
        console.log(e);
      });
  };

  useEffect(retrieveData, [start, end]);

  return (
    <Card>
      <CardHeader
        title="Dettes des vendeurs"
        subheader={"Dette totale : " + fNumber(total)}
      />
      <Stack direction="column" paddingBottom={3} spacing={3}>
        <ChartWrapperStyle dir="ltr">
          {AuthService.hasRole("ROLE_ADMIN") ||
          AuthService.hasRole("ROLE_MANAGER") ||
          AuthService.hasRole("ROLE_SUPERVISEUR") ? (
            isBusy ? (
              <Box sx={{ position: "absolute", top: "20%", left: "40%" }}>
                <CircularProgress />
              </Box>
            ) : chartData.length === 0 ? (
              <Box sx={{ alignItems: "center", justifyContent: "center" }}>
                <Typography variant="h5">Aucune donnée à afficher!</Typography>
              </Box>
            ) : (
              <ReactApexChart
                type="pie"
                series={chartData}
                options={chartOptions}
                height={280}
              />
            )
          ) : (
            <Label variant="ghost" color="error">
              Accès restreint!!!
            </Label>
          )}
        </ChartWrapperStyle>
      </Stack>
    </Card>
  );
}
