import * as Yup from "yup";
import { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import { format } from "date-fns";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import {
  LoadingButton,
  LocalizationProvider,
  MobileDatePicker,
} from "@material-ui/lab";
import { Box, Grid, MenuItem, Switch } from "@mui/material";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import React from "react";
import { fShortenNumber } from "../../../utils/formatNumber";

// ----------------------------------------------, ------------------------
const PAY_METHODS = [
  { label: "TMONEY", value: "TMONEY" },
  { label: "FLOOZ", value: "FLOOZ" },
];


export default function BookingHotelForm({
  handleLogin,
  serverErrors,
  isLoading,
  roomTypes,
}) {
  const [showPassword, setShowPassword] = useState(false);
  const [hrDate, setHrDate] = useState(new Date());

  const [pricePerDay, setPricePerDay] = useState(0);
  const [roomAvailable, setRoomAvailable] = useState(false);

  const FieldsSchema = Yup.object().shape({
    lastname: Yup.string().required("Saisir le nom de famille"),
    firstname: Yup.string().required("Saisir le(s) prénom(s)"),
    email: Yup.string()
      .email("Email non valide!")
      .required("Saisir une adresse email"),
    phone: Yup.string().required("Saisir un numero de telephone"),
    nbdays: Yup.number()
      .min(1, "Saisir au minimum 1 jour!")
      .required(`Saisir le nombre de jour(s)`),
    sortKey: Yup.string().required("Veuillez selectionner le type de chambre"),
    network: Yup.string().required("Veuillez selectionner le moyen de paiement"),
  });

  const formik = useFormik({
    initialValues: {
      bookingtype: "hotel",
      lastname: "",
      firstname: "",
      bookingstartdate: "",
      bookingenddate: "",
      bookingstarthour: "",
      email: "",
      phone: "",
      nbadults: 0,
      nbchildren: 0,
      needresto: false,
      nbstudios: 0,
      nbconfrooms: 0,
      nbdays: 0, //egal à NB Jr pour res. hotel
      totalchildren: 0,
      totalzoo: 0,
      payphonenumber: "",
      network: "",
      sortKey: "",
      roomType: "",
      roomAvailable: false,
    },
    validationSchema: FieldsSchema,
    onSubmit: (values) => {
      const dt = format(hrDate, "yyyy-MM-dd");
      values.bookingstartdate = dt;
      values.payphonenumber = values.phone;
      values.network = values.network;
      handleLogin(values);
    },
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;

  const handleDateChange = (date) => {
    setHrDate(date);
  };

  //calcul automatique du montant total des adultes
  const handleChangeTA = (event) => {
    let total = event.target.value * pricePerDay;
    setFieldValue("nbdays", event.target.value);
    setFieldValue("totalzoo", total); //recalcul du total global
  };

  const handleChange = (event) => {
    let room = roomTypes.find(
      (element) => element.sortKey === event.target.value
    );
    let tot = room.pricePerDay * formik.values.nbdays;
    setFieldValue("totalzoo", tot);
    setFieldValue("sortKey", room.sortKey);
    setFieldValue("roomType", room.roomType);
    setPricePerDay(room.pricePerDay);
    if((room.dispo*1) !== 0){
      setRoomAvailable(true);
    }else{
      setRoomAvailable(false);
      setFieldValue("nbdays", 0);
      setFieldValue("totalzoo", 0);
    }
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        {serverErrors && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            paddingBottom={5}
          >
            <Typography sx={{ color: "red" }}>{serverErrors}</Typography>
          </Stack>
        )}

        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <TextField
              size="small"
              variant="filled"
              fullWidth
              label="Nom"
              disabled={isLoading}
              {...getFieldProps("lastname")}
              error={Boolean(touched.lastname && errors.lastname)}
              helperText={touched.lastname && errors.lastname}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              variant="filled"
              fullWidth
              autoComplete="firstname"
              label="Prénoms"
              disabled={isLoading}
              {...getFieldProps("firstname")}
              error={Boolean(touched.firstname && errors.firstname)}
              helperText={touched.firstname && errors.firstname}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              size="small"
              variant="filled"
              fullWidth
              autoComplete="phone"
              type="text"
              label="Téléphone"
              disabled={isLoading}
              {...getFieldProps("phone")}
              error={Boolean(touched.phone && errors.phone)}
              helperText={touched.phone && errors.phone}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              size="small"
              variant="filled"
              fullWidth
              select
              label="Moyen Paiement"
              disabled={isLoading}
              error={Boolean(touched.network && errors.network)}
              helperText={touched.network && errors.network}
              {...getFieldProps("network")}
            >
              {PAY_METHODS.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              variant="filled"
              fullWidth
              autoComplete="email"
              type="email"
              label="Adresse Email"
              disabled={isLoading}
              {...getFieldProps("email")}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
          </Grid>

          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDatePicker
                id="date"
                label="Date"
                inputFormat="dd/MM/yyyy"
                value={hrDate}
                disabled={isLoading}
                onChange={handleDateChange}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    variant="filled"
                    {...params}
                    fullWidth
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              variant="filled"
              fullWidth
              select
              label="Type de chambre"
              disabled={isLoading}
              onChange={handleChange}
              error={Boolean(touched.sortKey && errors.sortKey)}
              helperText={touched.sortKey && errors.sortKey}
            >
              {roomTypes.map((room) => (
                <MenuItem key={room.sortKey} value={room.sortKey}>
                  {room.roomType +
                    "    [" +
                    fShortenNumber(room.pricePerDay) +
                    " FCFA] - Dispo : " +
                    room.dispo}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={6}>
            <TextField
              size="small"
              variant="filled"
              fullWidth
              type="number"
              s
              label="NB. jour(s)"
              disabled={!roomAvailable || isLoading}
              {...getFieldProps("nbdays")}
              onChange={(event) => {
                handleChangeTA(event);
              }}
              error={Boolean(touched.nbdays && errors.nbdays)}
              helperText={touched.nbdays && errors.nbdays}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              variant="filled"
              fullWidth
              type="number"
              label="Coût total (FCFA)"
              disabled={true}
              {...getFieldProps("totalzoo")}
              value={formik.values.totalzoo}
            />
          </Grid>
        </Grid>

        <Grid
          container
          paddingTop={4}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          justifyContent="flex-end"
        >
          <Grid item xs={3}>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isLoading}
            >
              Envoyer
            </LoadingButton>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
