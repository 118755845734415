import React, { useState, useEffect, useContext } from "react";
// material
import { Box, Grid, Container, Typography } from "@material-ui/core";
// components
import Page from "../components/Page";
import {
  AppTasks,
  AppNewUsers,
  AppBugReports,
  AppItemOrders,
  AppNewsUpdate,
  AppWeeklySales,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppCurrentSubject,
  AppConversionRates,
  AppPeriodSector,
  AppMonthlyZooBookings,
  AppMonthlyHotelBookings,
  AppMonthlyZooHotelBookings,
  AppMonthlyPayements,
  AppCurrentDriversDebt,
  AppGlobalStatChart,
} from "../components/_dashboard/app";
import { format } from "date-fns";
import moment from "moment";

import StoreOutService from "../services/storeout.service";
import ParamService from "../services/param.service";
import EventBus from "../services/common/EventBus";
import { GlobalContext } from "src/context/Provider";
import BookingsService from "../services/bookings.service";

// ----------------------------------------------------------------------

export default function DashboardApp() {
  let stDt = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  let enDt = new Date(new Date().getFullYear(), new Date().getMonth()+1, 0);

  const [start, setStart] = useState(format(stDt, "yyyy-MM-dd"));
  const [end, setEnd] = useState(format(enDt, "yyyy-MM-dd"));

  const [resZooData, setResZooData] = useState([]);
  const [resHotelData, setResHotelData] = useState([]);
  const [zooTotalAmount, setZooTotalAmount] = useState(0);
  const [hotelTotalAmount, setHotelTotalAmount] = useState(0);
  const [isBusy, setIsbusy] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setIsbusy(true);
      const params = {
        start,
        end,
        idKey: "RES-Z",
      };

      BookingsService.getList(params)
        .then((response) => {
          if (response.data !== undefined) {
            setResZooData(response.data);
          }
        })
        .catch((error) => console.log(JSON.stringify(error)));
      setIsbusy(false);
    }
    fetchData();
  }, [start, end]);

  useEffect(() => {
    async function fetchData() {
      setIsbusy(true);
      const params = {
        start,
        end,
        idKey: "RES-H",
      };

      BookingsService.getList(params)
        .then((response) => {
          if (response.data !== undefined) {
            setResHotelData(response.data);
          }
        })
        .catch((error) => console.log(JSON.stringify(error)));
      setIsbusy(false);
    }
    fetchData();
  }, [start, end]);

  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6}>
            <AppPeriodSector setStart={setStart} setEnd={setEnd} />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <AppMonthlyZooBookings
              start={start}
              end={end}
              data={resZooData}
              isBusy={isBusy}
              setZooTotalAmount={setZooTotalAmount}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AppMonthlyHotelBookings
              total={0}
              start={start}
              end={end}
              data={resHotelData}
              isBusy={isBusy}
              setHotelTotalAmount={setHotelTotalAmount}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AppMonthlyZooHotelBookings
              total={0}
              start={start}
              end={end}
              data={resZooData}
              isBusy={isBusy}
              zooTotalAmount={zooTotalAmount}
              hotelTotalAmount={hotelTotalAmount}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
