import { Link as RouterLink, useNavigate } from "react-router-dom";
// material
import { styled } from "@material-ui/core/styles";
import {
  Card,
  Stack,
  Link,
  Container,
  Typography,
  Grid,
} from "@material-ui/core";
// layouts
import AuthLayout from "../layouts/AuthLayout";
// components
import Page from "../components/Page";
import { MHidden } from "../components/@material-extend";
import { LoginForm } from "../components/authentication/login";
// import AuthSocial from "../components/authentication/AuthSocial";
// import AuthService from "../services/common/auth.service";
import { useState } from "react";
import { APP_DESC } from "../utils/appConstants";
import sideimage from "../img/logo.png"; // Tell webpack this JS file uses this image
import { Auth } from "aws-amplify";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const [serverErrors, setServerErrors] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (email, password) => {
    setIsLoading(true);
    setServerErrors("");
    try {
      await Auth.signIn(email, password);
      navigate("/dashboard");
      //window.location.reload();
    } catch (err) {
      console.log({ err });
      setServerErrors(err.message);
      setIsLoading(false);
    }
  };

  return (
    <RootStyle title="Connexion">
      <MHidden width="mdDown">
        <SectionStyle>
          <img src={sideimage} alt="login" />
          <Grid container sx={{ marginTop: 10 }}>
            <Grid item xs={12} sx={{ justifyContent: "center" }}>
              <Typography
                variant="body"
                sx={{
                  px: 5,
                  mt: 10,
                  mb: 5,
                }}
              >
                Contacts
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ justifyContent: "center" }}>
              <Typography
                variant="body"
                sx={{
                  alignItems: "center",
                  alignContent: "center",
                  px: 5,
                  mt: 10,
                  mb: 5,
                }}
              >
                Tel: +228 98 47 87 87 / 91 64 33 33
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ justifyContent: "center" }}>
              <Typography
                variant="body"
                sx={{
                  alignItems: "center",
                  alignContent: "center",
                  px: 5,
                  mt: 10,
                  mb: 5,
                }}
              >
                Email : contact@talinnaminizootogo.com
              </Typography>
            </Grid>
          </Grid>
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Accès à la pagne d'administration
            </Typography>
            <Typography sx={{ color: "text.secondary" }}>
              Entrer les identifiants de connexion.
            </Typography>
          </Stack>

          <LoginForm
            handleLogin={handleLogin}
            serverErrors={serverErrors}
            isLoading={isLoading}
          />

          <MHidden width="smUp">
            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              Pas encore inscrit?&nbsp;
              <Link variant="subtitle2" component={RouterLink} to="/register">
                Cliquer ici pour vous inscrire...
              </Link>
            </Typography>
          </MHidden>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
