import * as Yup from "yup";
import React, { forwardRef, useState } from "react";
import { Icon } from "@iconify/react";
import { useFormik, Form, FormikProvider } from "formik";
import { Link as RouterLink } from "react-router-dom";
// material
import { Stack, TextField, Button, Typography } from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import MobileDatePicker from "@material-ui/lab/MobileDatePicker";
import { Grid } from "@mui/material";
import { format, formatDistance, formatRelative, subDays } from "date-fns";
import { uploadFile } from "react-s3";

// ----------------------------------------------------------------------

export default function CreateBookingForm({
  handleSave,
  serverErrors,
  isLoading,
}) {
  const [hrDate, setHrDate] = useState(new Date());

  const CreateSchema = Yup.object().shape({
    tx_reference: Yup.string().required("N° de réservation requis! "),
  });

  const formik = useFormik({
    initialValues: {
      tx_reference: "",
      visit_date: "",
    },
    validationSchema: CreateSchema,
    onSubmit: (values) => {
      const dt = format(hrDate, "yyyy-MM-dd");
      values.visit_date = dt;
      handleSave(values);
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  const handleDateChange = (date) => {
    setHrDate(date);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        {serverErrors && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            paddingBottom={5}
          >
            <Typography sx={{ color: "red" }}>{serverErrors}</Typography>
          </Stack>
        )}
        <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDatePicker
                label="Date de la visite"
                inputFormat="dd/MM/yyyy"
                value={hrDate}
                disabled={isLoading}
                onChange={handleDateChange}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="N° de réservation"
              type="text"
              disabled={isLoading}
              {...getFieldProps("tx_reference")}
              error={Boolean(touched.tx_reference && errors.tx_reference)}
              helperText={touched.tx_reference && errors.tx_reference}
            />
          </Grid>
        </Grid>

        <Grid
          container
          paddingTop={3}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          justifyContent="space-between"
        >
          <Grid item>
            <Button
              fullWidth
              size="large"
              variant="contained"
              component={RouterLink}
              to="/dashboard/bookings"
            >
              Retour aux réservations
            </Button>
          </Grid>
          <Grid item>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isLoading}
            >
              Confirmer
            </LoadingButton>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
